import { useState } from "react";

const Accrodian = () => {
  return (
    <div className="accordion-list">
      <ul>
        <AccrodianItem
          number={"01"}
          title={"What sets us apart from our competitors?"}
          description={
            "We consistently prioritize analyzing clients' needs, working together with them to provide a high-quality product. We know how to scale up or down depending on the situation."
          }
        />
        <AccrodianItem
          number={"02"}
          title={
            "How can we create software apps while staying within your budget?"
          }
          description={
            "We strive to produce products within our clients' budgets, so we prefer to use technology and architecture where the execution and construction costs are lower without sacrificing quality. We'll stay up to speed on the latest technology."
          }
        />
        <AccrodianItem
          number={"03"}
          title={"You have a business concept but don't know where to begin?"}
          description={
            "We like assisting our clients in transforming business logic into a well-defined technological solution. We will do a thorough analysis of the requirements and collaborate closely with you to develop the product. To maintain client satisfaction, we ensure continuous delivery throughout the development cycle."
          }
        />
      </ul>
    </div>
  );
};

const AccrodianItem = ({
  number,
  title,
  description,
  isOpenDefault = false,
}) => {
  const [open, setOpen] = useState(isOpenDefault);

  const handleOnClick = () => {
    setOpen(!open);
  };

  return (
    <li>
      <a className={open ? "collapse" : "collapsed"} onClick={handleOnClick}>
        <span>{number}</span> {title}{" "}
        <i className="bx bx-chevron-down icon-show"></i>
        <i className="bx bx-chevron-up icon-close"></i>
      </a>
      <div
        id="accordion-list-3"
        className={`collapse ${open ? "show" : ""}`}
        data-bs-parent=".accordion-list"
      >
        <p>{description}</p>
      </div>
    </li>
  );
};

export default Accrodian;
