import { NavLink } from "react-router-dom";
import { Footer, Header } from "../../components";

const ContactScreen = () => {
  const handleOnSubmit = (event) => {
    event.preventDefault();
    const ApiData = {
      name: event.target[0].value,
      email: event.target[1].value,
      subject: event.target[2].value,
      content: event.target[3].value,
    };
    var formattedBody = event.target[3].value;
    var mailToLink =
      "mailto:vishnu@wevainfotech.com?subject=[Weva infotech] message from wevainfotech.com&body=" +
      encodeURIComponent(formattedBody);
    window.location.href = mailToLink;
  };

  return (
    <>
      <Header />

      <main id="main">
        <section id="breadcrumbs" class="breadcrumbs">
          <div class="container">
            <ol>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Contact</li>
            </ol>
            <h2>Contact us</h2>
          </div>
        </section>

        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title">
              <p>
                We are at your service at all times. You can reach out to us for
                any business consulting or software development needs. We will
                be delighted to help you and grow together.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-5 d-flex align-items-stretch">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>
                      15/759, floor-0, Puramundekkad Road, Near Vattamkulam
                      Panchayath Office, Malappuram, Kerala, 679578
                    </p>
                  </div>

                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>
                      info@wevainfotech.com <br /> vishnu@wevainfotech.com
                    </p>
                  </div>

                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>
                      +91 7025132043 <br />
                      +39 3519352247
                    </p>
                  </div>

                  {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
                    frameborder="0"
                    // style="border:0; width: 100%; height: 290px;"
                    allowfullscreen
                  ></iframe> */}
                </div>
              </div>

              <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                <form
                  onSubmit={handleOnSubmit}
                  role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="name">Your Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="name">Your Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Message</label>
                    <textarea
                      className="form-control"
                      name="message"
                      rows="10"
                      required
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>

                  <div className="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default ContactScreen;
