import { NavLink } from "react-router-dom";
import { Accrodian } from "../../../components";

const HomeMainContent = () => {
  return (
    <main id="main">
      <section id="about" className="about">
        <div className="container">
          <div className="section-title">
            <h2>About Us</h2>
          </div>

          <div className="row content">
            <div className="col-lg-6">
              <p>
                WEVA infotech creates new and creative products and services
                that address all aspects of communication and information. Web
                design and development, custom apps, ERPs, CRMs, e-commerce
                solutions, business-to-business applications, business-to-client
                applications, managed hosting, and internet portal
                administration are just a few of the services we provide. The
                quality of our work is attested to many satisfied clients all
                around the world.
              </p>
              <p>
                Weva Infotech's business strategy is to provide the highest
                quality product, complete client satisfaction, on-time solution
                delivery, and the industry's best quality/price ratio.
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <p>
                Weva Infotech is dedicated to exporting high-quality software
                around the world as a pioneer in technology exploration. Weva
                Infotech's main goal is to create and promote breakthrough
                information technologies for multi-user applications.
              </p>
              <p>
                Through a pleasant, creative, and dedicated firm personnel, we
                strive to provide a high level of product user friendliness.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="why-us" className="why-us section-bg">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
              <div className="content">
                <h3>
                  For many clients in the IT industry,{" "}
                  <strong>We are the preferred option.</strong>
                </h3>
                <p>
                  We have a number of clients all around the world, and we
                  consistently provide them with high-quality software
                  technology.
                </p>
              </div>

              <Accrodian />
            </div>

            <div
              className="col-lg-5 align-items-stretch order-1 order-lg-2 img why-us-image"
              // style='background-image: url("");'
            >
              &nbsp;
            </div>
          </div>
        </div>
      </section>

      <section id="skills" className="skills">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center">
              <img src="/assets/img/skills.png" className="img-fluid" alt="" />
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0 content">
              <h3>We Create the solution in the technology that you need.</h3>
              <p className="fst-italic">
                We can create a solution to any information technology problem
                you may have. Our main focus is on the services listed below.
              </p>

              <div className="skills-content">
                <div className="progress">
                  <span className="skill">
                    Web/Mobile application Development{" "}
                    <i className="val">100%</i>
                  </span>
                  <div className="progress-bar-wrap">
                    <div
                      className="progress-bar"
                      style={{ width: "100%" }}
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="100"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>

                <div className="progress">
                  <span className="skill">
                    Desktop application Development <i className="val">90%</i>
                  </span>
                  <div className="progress-bar-wrap">
                    <div
                      className="progress-bar"
                      style={{ width: "90%" }}
                      role="progressbar"
                      aria-valuenow="90"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>

                <div className="progress">
                  <span className="skill">
                    Artificial Intelligence / Machine Learning{" "}
                    <i className="val">75%</i>
                  </span>
                  <div className="progress-bar-wrap">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "75%" }}
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>

                <div className="progress">
                  <span className="skill">
                    Block Chain Technology <i className="val">60%</i>
                  </span>
                  <div className="progress-bar-wrap">
                    <div
                      className="progress-bar"
                      style={{ width: "60%" }}
                      role="progressbar"
                      aria-valuenow="55"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="services section-bg">
        <div className="container">
          <div className="section-title">
            <h2>Services</h2>
            <p>
              We provide solutions to any technology problems you may have in
              your daily life. The following technologies are covered by our
              services. In reality, this limit is infinite.
            </p>
          </div>

          <div className="row">
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  <a href="">Web / Mobile Application development</a>
                </h4>
                <p>
                  Develop a custom software application that adapts to changing
                  company and end-user needs. TatvaSoft assists businesses in
                  driving growth and overcoming business obstacles by designing
                  fully functional, feature-rich, and interoperable web and
                  mobile applications.
                </p>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-file"></i>
                </div>
                <h4>
                  <a href="">Cloud & DevOps</a>
                </h4>
                <p>
                  Applying automation to conventional business systems will help
                  you seamlessly manage operations and business processes. And
                  using cloud, businesses can easily collaborate and accelerate
                  their business development cycle with high-quality solutions
                  and efficiency.
                </p>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-tachometer"></i>
                </div>
                <h4>
                  <a href="">Blockchain Technology</a>
                </h4>
                <p>
                  For many platforms, our dedicated full-stack blockchain
                  developers create exchange platforms, smart contract
                  administration systems, and eWallet apps. You can engage
                  blockchain engineers to create bitcoin wallets, both online
                  and on mobile devices, that are more secure.
                </p>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-layer"></i>
                </div>
                <h4>
                  <a href="">AI / Machine Learning</a>
                </h4>
                <p>
                  Make your machines as clever and as wise as possible! With the
                  greatest AI development solutions from Weva Infotech, you can
                  give your gadgets the power to think, interpret, evaluate, and
                  decide like 'human beings.'
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="cta" className="cta">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 text-center text-lg-start">
              <h3>Call To Action</h3>
              <p>
                {" "}
                We are at your service at all times. You can reach out to us for
                any business consulting or software development needs. We will
                be delighted to help you and grow together.
              </p>
            </div>
            <div className="col-lg-3 cta-btn-container text-center">
              <NavLink to="/contact">
                <a className="cta-btn align-middle">Call To Action</a>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default HomeMainContent;
