import "./App.scss";
import { HomeScreen, ContactScreen } from "./screens";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  // return <HomeScreen />;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="contact" element={<ContactScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
