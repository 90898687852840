import { Layout, Menu, Breadcrumb } from "antd";
import HomeCarousal from "./container/HomeCarousal";
// import Icon from "../..//assets/logo.png";
import styles from "./styles.module.scss";
import { Footer, Header } from "../../components";
import HomeMainContent from "./container/HomeMainContent";

// const { Header, Content, Footer } = Layout;

const HomeScreen = () => {
  return (
    <>
      <Header />
      <HomeCarousal />
      <HomeMainContent />

      <Footer />

      {/*  */}
      {/* <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a> */}
    </>
  );
};

export default HomeScreen;
